import reportWebVitals from "./reportWebVitals";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { toggleMessage } from "./utils/events";
import { getDataByTokenLog, getTokenLog } from "./utils/token";

function checkLocalSession() {
  let response = false;
  let message = "";
  let token = getTokenLog();
  let dataBytokenLog = getDataByTokenLog();
  if (token && dataBytokenLog) {
    let dataByToken: {
      dateExp: string;
      displayName: string;
      roles: string;
      tokenExp: number;
      userId: string;
      userName: string;
    } = JSON.parse(dataBytokenLog);

    try {
      let dateTokenExp = new Date(dataByToken.dateExp);
      let toDay = new Date();
      if (dateTokenExp <= toDay) {
        message = "Tu sesión expiró.";
        sessionStorage.removeItem("tokenLog");
        sessionStorage.removeItem("dataBytokenLog");
      } else {
        response = true;
      }
    } catch (error) {
      message = "Debe iniciar sesión";
    }
  }

  if (message) toggleMessage("error", message);
  return response;
}

const PublicRoutes = lazy(() => import("./routes/publicRoutes"));
const PrivateRoutes = lazy(() => import("./routes/privateRoutes"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<React.Fragment>{checkLocalSession() ? <PrivateRoutes /> : <PublicRoutes />}</React.Fragment>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
