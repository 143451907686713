export const eventBus = {
  toastOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  toastDispatch(
    event: keyof typeof EVENTS,
    data: CustomEventInit<{ text: string; type: "warning" | "error" | "info" | "success" }>,
    duration: number,
    onCloseDisabled: boolean,
    textSize: number,
    open: boolean
  ) {
    let newEvent = new CustomEvent(event, {
      ...data,
      detail: { ...data.detail, duration, onCloseDisabled, textSize, open },
    });
    document.dispatchEvent(newEvent);
  },

  loadOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  loadDispatch(event: keyof typeof EVENTS, data: CustomEventInit<{ value: boolean }>) {
    let newEvent = new CustomEvent(event, data);
    document.dispatchEvent(newEvent);
  },
};

const EVENTS = {
  TOGGLE_MESSAGE: "TOGGLE_MESSAGE",
  IS_LOADING: "IS_LOADING",
};

export const toggleMessage = (
  type: "warning" | "error" | "info" | "success",
  text,
  duration = 5000,
  onCloseDisabled = false,
  textSize = 14,
  open = true
) => {
  eventBus.toastDispatch(
    "TOGGLE_MESSAGE",
    {
      detail: { text: text || "Ha ocurrido un error inesperado", type: type },
    },
    duration,
    onCloseDisabled,
    textSize,
    open
  );
};

export const setIsLoading = (value: boolean) => {
  eventBus.loadDispatch("IS_LOADING", { detail: { value: value } });
};
